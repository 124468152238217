<template>
  <div v-if="quizResult" class="pt-6">
    <v-card v-for="q in quizResult.quiz.questions" :key="q.id" class="ml-1 mr-1 mb-1" :disabled="offline">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            {{ q.question }}
          </v-col>
          <v-col cols="2">
            {{ q.id + "."}}
          </v-col>
          <v-col v-for="team in quizResult.teams" :key="team.teamId" cols="2" :style="{backgroundColor: team.color, whiteSpace: 'nowrap' }">
            {{ getAnswer(team, q.id) }}
            <v-icon v-if="isAnswerCorrect(team, q)" color="green">mdi-check-bold</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="ml-1 mr-1 mt-4" :disabled="offline" color="blue lighten-4">
      <v-container fluid>
        <v-row>
          <v-col cols="2">
            {{ "#" }}
          </v-col>
          <v-col v-for="team in quizResult.teams" :key="team.teamId" cols="2" :style="{backgroundColor: team.color}">
            {{ team.result || "0" }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { getAnswers } from "@/base/quiz.js"

export default {
  name: "Quiz",
  props: [
    'userAndTeam'
  ],
  data: function () {
    return {
      quizResult: undefined,
      updateInterval: undefined,
      offline: true,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    getAnswer(team, question) {
      return team.answers?.[question]?.answer ?? "-";
    },
    isAnswerCorrect(team, question) {
      return team.answers?.[question.id]?.answer === question.answer;
    },
    async refreshQuiz() {
      getAnswers().then( quizResult => {
        if (quizResult) {
          this.offline=false;
          quizResult.quiz.questions.forEach( question => {
            quizResult.teams.forEach( team => {
              const isCorrect = this.isAnswerCorrect(team, question);
              if (isCorrect) {
                team.answers[question.id].isCorrect = true;
                team.result = team.result ? ++team.result : 1;
              }
            });
          });
          this.quizResult = quizResult;
          this.quizResult.teams = this.quizResult.teams.filter( t => t.teamId !== "-1"); 
        } else {
          this.offline=true;
        }
      });
    }
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    this.refreshQuiz();
    this.updateInterval = setInterval(() => {
      this.refreshQuiz();
    }, 5000);
  }
};
</script>
<style scoped>
.image-root {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: aliceblue;
}
</style>
