import axios from "axios";
import { getUserId } from "./user";

export const URL_DELETE_IMAGE = "https://api.col.spritfest.se/upload/dcim/delete/";
export const URL_UPLOAD_IMAGE = "https://api.col.spritfest.se/upload/dcim/";
export const URL_UPLOAD_LIST = "https://api.col.spritfest.se/upload/list";

export async function uploadImages(files, feedBack) {
  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent < 100 && feedBack) {
      feedBack.progress = percent;
    }
  };
  try {
    if (!files.length) {
      return;
    }
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i].name)
    }
    return await axios.post(URL_UPLOAD_IMAGE + getUserId(), formData, { onUploadProgress });
  }
  catch (err) {
    console.error(err);
  }
}

const CACHE = { deletes: -1 };
export async function getImageList(fromImage) {
  try {
    let url = URL_UPLOAD_LIST;
    if (fromImage) {
      url += "/" + fromImage;
    }
    let imageData = (await axios.get(url)).data;
    if (imageData.deletes != CACHE.deletes) {
      CACHE.deletes = imageData.deletes;
      if (!imageData.isFull) {
        return getImageList();
      }
    }
    return imageData;
  }
  catch (err) {
    console.error(err);
  }
}

export async function deleteImage(image) {
  try {
    return await axios.get(URL_DELETE_IMAGE + image + "/" + getUserId());
  }
  catch (err) {
    console.error(err);
  }
}
