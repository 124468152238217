<template>
  <div>
    <v-card class="ml-2 mr-2 mt-4 mb-6">
      <div class="ml-3 pt-2">
        <h2>GoKartor:</h2>
        <small>
          <ul>
            <li>Kartsnitt från <a href="https://kartor.gokartor.se/#11/58.2868/12.0704" target="blank">GoKartor</a></li>
        </ul>
        </small>
      </div>
      <v-container fluid v-for="map in goKartor.maps" :key="map.file">
        <h2>{{ map.name }}:</h2>
        <img :src="map.file" width="100%">
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Run",
  props: [
    'userAndTeam'
  ],
  data: () => {
    return {
      goKartor: require("@/content/goKartor.json"),
    };
  },
};
</script>
<style scoped>

</style>
