<template>
  <div>
    <v-card class="ml-2 mr-2 mt-4 mb-6">
      <div class="ml-3 pt-2">
        <h2>Planerade rundor:</h2>
        <small>
          <ul>
            <li>Tror bara vi kommer klara att parkera buss vid Stenshults IP</li>
            <li>På 25km rundan "Stenshult och Vargfjället" bär Conny fika</li>
            <li>Det bör även gå att fixa bil för löpare som vill bryta efter Vargfjället</li>
        </ul>
        </small>
      </div>
      <v-container fluid v-for="route in strava.routes" :key="route.id">
        <div 
          class="strava-embed-placeholder"
          data-embed-type="route"
          :data-embed-id="route.id"
          data-units="metric"
          data-style="hybrid"
          data-from-embed="false"
          data-full-width="true" >
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { initStrava } from "@/base/strava.js";

export default {
  name: "Run",
  props: [
    'userAndTeam'
  ],
  data: () => {
    return {
      strava: require("@/content/strava.json"),
    };
  },
  mounted() {
    initStrava();
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style scoped>

</style>
