<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <v-card @click="dialog = false">
      <v-card-text class="pt-6 pb-2">
        <h1>Springavilse på Västkusten</h1><br>
        <h2>Brumanstorp (Basecamp):</h2>
        <h3>23-26 Maj</h3><br>
        <ul>
          <li>Address: Brumanstorp 104 Trollhättan</li>
          <li>Badbrygga: 150m</li>
          <li>Bastu: 150m</li>
          <li>Wifi: ja</li>
          <li>Airhockey: ja</li>
          <li>Pingisbord: ja</li>
          <li>All-inclusive: nästan</li>
        </ul>
        <br>
        Hitta hit:
        <br>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1056432.8395883094!2d14.180805608685665!3d58.85854547093188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x465f76bb7d6f5019%3A0x73d7154fde608e21!2sIKEA%20Stockholm%20-%20Kungens%20Kurva%2C%20Ekg%C3%A5rdsv%C3%A4gen%2C%20Kungens%20Kurva!3m2!1d59.2722871!2d17.916065!4m5!1s0x46451707283a6711%3A0xe34d1f7d1e6bbd50!2sBrumanstorp%20104%2C%20461%2091%20Trollh%C3%A4ttan!3m2!1d58.3057879!2d12.1290257!5e0!3m2!1sen!2sse!4v1711638009487!5m2!1sen!2sse"
          width="100%"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
        <br>
        <i>
          Det är alltså här vi ska basta, bada och bli fulla när vi inte springer.
        </i>
        <br><br><hr><br>
        <h1>Men Västkusten, då tänker ni:</h1><br>
        <h2>1. där klubbar man sälar</h2>
        <h2>2. det är jättelångt dit!</h2>
        <h2>3. vad läskigt</h2>
        <br>
        Allt detta är förvisso sant, men Röhl fixar ståndsmässig droska:<br>
        <img src="bussen-real.png" /><br>
        Vi kommer dessutom hålla oss långt från civilization.
        Temat blir burkmat och granar!
        <br><br><hr>
        <div align="center"><i>{{ getTeamName }}</i></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getUserId } from "@/base/user.js"
export default {
  name: "Info",
  props: ['show', 'userAndTeam'],
  data: function () {
    return {
      getUserId
    };
  },
  watch: {
  },
  methods: {

  },
  computed: {
    getTeamName() {
      const team =  this.userAndTeam?.team?.name || "?";
      const user =  this.userAndTeam?.userId?.substring(0,8) || "?";
      return team + "(" + user + ")";
    },
    dialog: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("close");
      }
    }
  },
  destroyed() {

  },
  mounted() {

  }
};
</script>
