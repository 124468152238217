<template>
  <v-app-bar app color="primary" light src="banner.webp" class="top-app-bar">

    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(140,140,100,.5), rgba(190,190,150,.7)"
      ></v-img>
    </template>

    <v-icon left :color="getStatusColor" @click="selectHeart()" class="on-top">mdi-bus</v-icon>

    <v-tabs v-model="tab" class="tabs-room-for-camera">
      <v-tab href="#Foto" class="tab-text">FOTO</v-tab>
      <v-tab v-if="hasUser" href="#Run" class="tab-text">STRAVA</v-tab>
      <v-tab v-if="hasUser" href="#GoKartor" class="tab-text">KARTOR</v-tab>
      <v-tab v-if="hasUser" href="#Schema" class="tab-text">SCHEMA</v-tab>
      <v-tab v-if="isGod" href="#QuizResult" class="tab-text">RESULT</v-tab>
      <v-tab v-if="isGod" href="#Users" class="tab-text">USERS</v-tab>
      <v-tab v-if="isGod" href="#Settings" class="tab-text">SETTINGS</v-tab>
      <v-file-input v-if="hasUser && isGod"
        prepend-icon="mdi-camera"
        hide-input
        multiple
        v-model="uploadFiles"
        class="mt-3 mr-6 ml-3"
        variant="filled"
      />
    </v-tabs>
    
    <v-file-input v-if="hasUser && !isGod" class="camera-button"
      prepend-icon="mdi-camera"
      hide-input
      multiple
      v-model="uploadFiles"
    />

    <Info :show="showDialog" @close="showDialog = false" :userAndTeam="userAndTeam"/>
  </v-app-bar>
</template>

<script>
import { uploadImages } from "@/base/upload.js";
import Info from './Info.vue'

export default {
  name: "TopBar",
  components: {
    Info
  },
  props: {
    userAndTeam: { type: Object, default: ()=>({}) },
  },
  data: function () {
    return {
      showDialog:false,
      activeTab: "",
      uploadFiles: [],
      feedBack: { progress: 0, uploading: false },
      updateInterval: undefined,
      resetCounter:0,
      refreshCounter: 0,
    };
  },
  watch: {
    async uploadFiles() {
      this.feedBack.progress = 0;
      this.feedBack.uploading = true;
      await uploadImages(this.uploadFiles, this.feedBack);
      this.feedBack.progress = 100;
      this.feedBack.uploading = false;
    },
    ["feedBack.progress"]() {
      this.$emit("feedBack", this.feedBack);
    }
  },
  methods: {
    selectHeart() {
      this.resetCounter++;
      setTimeout(() => this.resetCounter = 0, 5000);
      if (this.resetCounter > 10) {
        localStorage.clear();
        console.log("CLEARED");
      }
      this.showDialog = true;
    },
  },
  computed: {
    getTeamName() {
      return this.userAndTeam?.team?.name || "?";
    },
    hasUser() {
      return this.userAndTeam?.userId;
    },
    isGod() {
      return this.userAndTeam?.isGod;
    },
    getStatusColor() {
      if (this.isGod) {
        return "black";
      } else if (this.hasUser) {
        return "white";
      }
      return "grey";
    },
    tab: {
      set(tab) {
        this.$emit("MenuChange", tab);
        this.activeTab = tab;
      },
      get() {
        return this.activeTab;
      },
    },
  },
};
</script>
<style>
.top-app-bar {
  background-color: transparent;
}
.on-top {
  z-index: 2;
  background-color: transparent;
}
.camera-button {
  position: absolute;
  right: 5px;
  background-color: transparent;
}
.tabs-room-for-camera .v-slide-group--is-overflowing {
  margin-left: -60px;
  padding-right: 40px;
}
.tab-text {
  color: #000000ff !important;
  opacity: 1;
  font-weight: 700 !important;
}
.v-icon {
  opacity: 1 !important;
}
</style>