<template>
  <div v-if="data" class="pt-6">
    <v-card class="ml-1 mr-1 mb-1" v-for="settingKey in settings" :key="settingKey">
      <v-container fluid>
        <v-checkbox :label="settingKey" v-model="data[settingKey]" @change="saveSetting(settingKey)"/>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { getSettings, setSettings } from "@/base/settings.js"

export default {
  name: "Settings",
  data: function () {
    return {
      data: undefined,
      updateInterval: undefined,
      offline: true,
    };
  },
  computed: {
    settings() {
      return Object.keys(this.data);
    }
  },
  methods: {
    saveSetting(settingKey) {
      setSettings({[settingKey]: this.data[settingKey]}).then( data => {
        if (data) {
          this.data = data;
        }
      });
    },
    async refreshSettings() {
      getSettings().then( data => {
        if (data) {
          this.data = data;
        }
        this.offline = !data;
      });
    }
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    this.refreshSettings();
    this.updateInterval = setInterval(() => {
      this.refreshSettings();
    }, 5000);
  }
};
</script>

