
export const initStrava = () => {
    "use strict";
    function e(e, t) {
        (null == t || t > e.length) && (t = e.length);
        for (var n = 0, r = new Array(t); n < t; n++)
            r[n] = e[n];
        return r
    }
    const t = function(t) {
        var n = t.prefix
          , r = t.inbox
          , o = t.outbox
          , a = function() {
            try {
                return new EventTarget
            } catch (e) {
                return document.createDocumentFragment()
            }
        }();
        return r.addEventListener("message", (function(t) {
            if (function(e) {
                var t;
                return (null === (t = e.data) || void 0 === t ? void 0 : t[0]) === n
            }(t)) {
                var r = (l = t.data,
                d = 3,
                function(e) {
                    if (Array.isArray(e))
                        return e
                }(l) || function(e, t) {
                    var n = null == e ? null : "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
                    if (null != n) {
                        var r, o, a = [], i = !0, c = !1;
                        try {
                            for (n = n.call(e); !(i = (r = n.next()).done) && (a.push(r.value),
                            !t || a.length !== t); i = !0)
                                ;
                        } catch (e) {
                            c = !0,
                            o = e
                        } finally {
                            try {
                                i || null == n.return || n.return()
                            } finally {
                                if (c)
                                    throw o
                            }
                        }
                        return a
                    }
                }(l, d) || function(t, n) {
                    if (t) {
                        if ("string" == typeof t)
                            return e(t, n);
                        var r = Object.prototype.toString.call(t).slice(8, -1);
                        return "Object" === r && t.constructor && (r = t.constructor.name),
                        "Map" === r || "Set" === r ? Array.from(r) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? e(t, n) : void 0
                    }
                }(l, d) || function() {
                    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
                }())
                  , o = r[1]
                  , i = r[2]
                  , c = new Event(o);
                c.args = i,
                a.dispatchEvent(c)
            }
            var l, d
        }
        )),
        {
            addMessageListener: a.addEventListener.bind(a),
            removeMessageListener: a.removeEventListener.bind(a),
            sendMessage: function(e) {
                var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                o.postMessage([n, e, t], "*")
            }
        }
    };
    function n(e, t) {
        (null == t || t > e.length) && (t = e.length);
        for (var n = 0, r = new Array(t); n < t; n++)
            r[n] = e[n];
        return r
    }
    var r, o = function(e) {
        var t, r = (t = e,
        Object.fromEntries(Object.entries(t).filter((function(e) {
            return null != (t = e,
            r = 2,
            function(e) {
                if (Array.isArray(e))
                    return e
            }(t) || function(e, t) {
                var n = null == e ? null : "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
                if (null != n) {
                    var r, o, a = [], i = !0, c = !1;
                    try {
                        for (n = n.call(e); !(i = (r = n.next()).done) && (a.push(r.value),
                        !t || a.length !== t); i = !0)
                            ;
                    } catch (e) {
                        c = !0,
                        o = e
                    } finally {
                        try {
                            i || null == n.return || n.return()
                        } catch (err) { } 
                        finally {
                            if (c)
                                throw o
                        }
                    }
                    return a
                }
            }(t, r) || function(e, t) {
                if (e) {
                    if ("string" == typeof e)
                        return n(e, t);
                    var r = Object.prototype.toString.call(e).slice(8, -1);
                    return "Object" === r && e.constructor && (r = e.constructor.name),
                    "Map" === r || "Set" === r ? Array.from(r) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? n(e, t) : void 0
                }
            }(t, r) || function() {
                throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
            }())[1];
            var t, r
        }
        ))));
        return r && Object.keys(r).length >= 1 ? "?".concat(new URLSearchParams(r)) : ""
    };
    window.__STRAVA_EMBED_BOOTSTRAP__ || (r = function() {
        var e = ["route"];
        document.querySelectorAll(".strava-embed-placeholder").forEach((function(n) {
            var r, a, i = n.dataset, c = i.embedType, l = i.embedId, d = i.mapHash, u = function(e, t) {
                if (null == e)
                    return {};
                var n, r, o = function(e, t) {
                    if (null == e)
                        return {};
                    var n, r, o = {}, a = Object.keys(e);
                    for (r = 0; r < a.length; r++)
                        n = a[r],
                        t.indexOf(n) >= 0 || (o[n] = e[n]);
                    return o
                }(e, t);
                if (Object.getOwnPropertySymbols) {
                    var a = Object.getOwnPropertySymbols(e);
                    for (r = 0; r < a.length; r++)
                        n = a[r],
                        t.indexOf(n) >= 0 || Object.prototype.propertyIsEnumerable.call(e, n) && (o[n] = e[n])
                }
                return o
            }(i, ["embedType", "embedId", "mapHash"]), s = document.createElement("iframe");
            s.setAttribute("frameborder", 0);
            var f = "true" === u.fullWidth && e.includes(c)
              , b = "\n        ".concat(f ? "width: 100%;" : "width: 554px;", "\n        min-width: 250px;\n        max-width: 100%;\n        height: 650px;\n        display: block;\n      ");
            s.setAttribute("style", b);
            var y = o(u)
              , m = (null === (a = window.crypto) || void 0 === a || null === (r = a.randomUUID) || void 0 === r ? void 0 : r.call(a)) || (Math.random() + 1).toString(36).substring(2)
              , v = new URLSearchParams({
                ns: m,
                hostOrigin: window.location.origin,
                hostPath: window.location.pathname,
                hostTitle: document.title
            })
              , h = d ? "&mapHash=".concat(d) : ""
              , p = "".concat("https://strava-embeds.com", "/").concat(c, "/").concat(l).concat(y, "#").concat(v).concat(h);
            s.setAttribute("src", p),
            n.replaceWith(s);
            var w = t({
                prefix: m,
                inbox: window,
                outbox: s.contentWindow
            });
            w.addMessageListener("BROADCAST_IFRAME_HEIGHT", (function(e) {
                var t = e.args;
                s.style.height = "".concat(t, "px")
            }
            )),
            window.__STRAVA_EMBED_SETTINGS_CHANGE_LISTENER__ && w.addMessageListener("BROADCAST_SETTINGS_CHANGE", (function(e) {
                var t = e.args;
                window.__STRAVA_EMBED_SETTINGS_CHANGE_LISTENER__(t)
            }
            )),
            window.addEventListener("focus", (function() {
                w.sendMessage("BROADCAST_HOST_FOCUS")
            }
            ))
        }
        ))
    }
    ,
    window.__STRAVA_EMBED_BOOTSTRAP__ = r),
    window.__STRAVA_EMBED_BOOTSTRAP__()
};
