import axios from "axios";
import { getUserId } from "@/base/user.js"

export const URL_QUIZ = "https://api.col.spritfest.se/upload/quiz/";

export async function getQuiz() {
  try {
    const userId = getUserId();
    if (!userId) {
      return undefined;
    }
    const response = await axios.get(URL_QUIZ + getUserId());
    const quizInfo = response.data;
    return quizInfo;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getAnswers() {
  try {
    const response = await axios.get(URL_QUIZ + "answers/" + getUserId());
    const answers = response.data;
    return answers;
  }
  catch (err) {
    console.error(err);
  }
}

export async function setAnswer(questionId, answer) {
  try {
    const response = await axios.post(URL_QUIZ + questionId + "/" + getUserId() + "/" + answer);
    const answers = response.data;
    return answers;
  }
  catch (err) {
    console.error(err);
  }
}