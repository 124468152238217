<template>
  <div v-if="data" class="pt-6">
    <v-card class="ml-1 mr-1 mb-1" color="blue lighten-4">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            userId
          </v-col>
          <v-col  cols="3">
            Team
          </v-col>
          <v-col  cols="2">
            pics
          </v-col>
          <v-col  cols="2">
            God
          </v-col>
          <v-col  cols="2">
            Ban
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="ml-1 mr-1 mb-1" v-for="user in data.users" :key="user.userId">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            {{ getShortUserId(user.userId) }}
          </v-col>
          <v-col  cols="3">
            {{ getTeamFor(user.teamId) }}
          </v-col>
          <v-col  cols="2">
            {{ user.uploads }}
          </v-col>
          <v-col  cols="2">
            <v-icon :color="user.isGod ? 'green' : 'grey'" @click="toggleGod(user.userId)">mdi-check-bold</v-icon>
          </v-col>
          <v-col cols="2">
            <v-icon :color="user.isBanned ? 'green' : 'grey'" @click="toggleBan(user.userId)">mdi-check-bold</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { getUsers, banUser, godUser } from "@/base/user.js"

export default {
  name: "Quiz",
  props: [
    'userAndTeam'
  ],
  data: function () {
    return {
      data: undefined,
      updateInterval: undefined,
      offline: true,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    getShortUserId(userId) {
      return userId?.substring(0,8) ?? "-";
    },
    getTeamFor(teamId) {
      return this.data.teams[teamId]?.name;
    },
    toggleGod(userId) {
      godUser(userId).then( data => {
        this.data = data;
      });
    },
    toggleBan(userId) {
      banUser(userId).then( data => {
        if (data) {
          this.data = data;
        }
      });
    },
    async refreshUsers() {
      getUsers().then( data => {
        if (data) {
          this.data = data;
        }
        this.offline = !data;
      });
    }
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    this.refreshUsers();
    this.updateInterval = setInterval(() => {
      this.refreshUsers();
    }, 5000);
  }
};
</script>
<style scoped>
.image-root {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: aliceblue;
}
</style>
