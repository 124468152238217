<template>
  <div class="mt-2" align="center" @click="$emit('exit')">
    <h1 class="white-title">Välkommen till Springavilse på västkusten</h1>
    <img src="chatGpt1-Banner.png" width="100%">
    <h1 class="white-title">Brumanstorp - 2024</h1>
  </div>
</template>

<script>

export default {
  name: "Schema",
  props: [
    'userAndTeam'
  ],
  data: function () {
    return {
    };
  },

  mounted() {
    setTimeout(() => this.$emit('exit'), 10000);
  }

};
</script>
<style scoped>
.white-title {
  color: whitesmoke;
}
</style>
