import axios from "axios";
import { getUserId } from "@/base/user.js"

export const URL_SETTINGS = "https://api.col.spritfest.se/upload/settings/";

export async function getSettings() {
  try {
    const userId = getUserId();
    if (!userId) {
      return undefined;
    }
    const response = await axios.get(URL_SETTINGS + getUserId());
    return response.data;
  }
  catch (err) {
    console.error(err);
  }
}

export async function setSettings(settings) {
  try {
    const response = await axios.post(URL_SETTINGS + getUserId(), settings);
    return response.data;
  }
  catch (err) {
    console.error(err);
  }
}