<template>
  <div>
    <v-card class="ml-2 mr-2 mt-4 mb-6">
      <h2 class="ml-2 mr-2 mt-4 mb-6">Här kommer det ett schema...</h2>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "Schema",
  props: [
    'userAndTeam'
  ],
  data: function () {
    return {
    };
  },

  computed: {
  },

};
</script>
<style scoped>

</style>
