import axios from "axios";
export const getUserAndTeam = () => JSON.parse(localStorage.getItem("userAndTeam") ?? "{}");
export const getUserId = () => localStorage.getItem("userId") ?? "";
export const getTeamId = () => localStorage.getItem("teamId") || "-1";

export const setUserId = (userId) => localStorage.setItem("userId", userId ?? "");
export const setTeamId = (teamId) => localStorage.setItem("teamId", teamId ?? "");

export function generateUserId() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function getTeamColor() {
  const userAndTeam = getUserAndTeam();
  return userAndTeam?.team.color || "#7c00ff";
}

export const URL_UPDATE_USER = "https://api.col.spritfest.se/upload/user/";
export const URL_UPLOAD = "https://api.col.spritfest.se/upload/";

export async function updateUser(passCode = "-") {
  try {
    const response = await axios.post(URL_UPDATE_USER + getUserId() + "/" + passCode + "/" + getTeamId());
    const userAndTeam = response.data;
    localStorage.setItem("userAndTeam", JSON.stringify(userAndTeam));
    return userAndTeam;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getUserInfo() {
  try {
    const response = await axios.get(URL_UPDATE_USER + getUserId());
    const userAndTeam = response.data;
    localStorage.setItem("userAndTeam", JSON.stringify(userAndTeam));
    return userAndTeam;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getUsers() {
  try {
    const response = await axios.get(URL_UPDATE_USER + getUserId() + "/admin");
    const users = response.data;
    return users;
  }
  catch (err) {
    console.error(err);
  }
}

export async function banUser(userIdToBan) {
  try {
    const response = await axios.get(URL_UPLOAD + "ban/" + getUserId() + "/" + userIdToBan);
    const users = response.data;
    return users;
  }
  catch (err) {
    console.error(err);
  }
}

export async function godUser(userIdToGod) {
  try {
    const response = await axios.get(URL_UPLOAD + "god/" + getUserId() + "/" + userIdToGod);
    const users = response.data;
    return users;
  }
  catch (err) {
    console.error(err);
  }
}