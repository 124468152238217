<template>
  <div class="image-root" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">

    <div v-if="fullScreenImageLoading" class="img-div-loader" @click="selectImage(undefined)">
      <v-progress-circular
        class="img-div"
        indeterminate
        color="grey lighten-5"
      ></v-progress-circular>
    </div>
    <div :style="{ position: 'relative', left: getSwipeDistance(), overflow: 'hidden'}">
      <div v-if="fullScreenImage" class="img-div" :style="{backgroundImage: getImageUrl(fullScreenImage), position: 'relative',}" @click="selectImage(undefined)">
        <div v-if="isGod" align="center" class="user-id"><small>{{ fullScreenImage.split("_")[1].split("-")[0] }}</small></div>
      </div>
    </div>
    <div v-if="fullScreenImage && (isMyImage(fullScreenImage) || isGod)" class="delete-image-button">
      <v-icon @click="deleteImage(fullScreenImage)">mdi-delete</v-icon>
    </div>
    <div v-if="fullScreenImage" class="close-image-button">
      <v-icon @click="fullScreenImage = undefined">mdi-close</v-icon>
    </div>

    <div :style="{position: 'relative', left: getSwipeDistance()}">
      <v-row v-if="!fullScreenImageLoading && !fullScreenImage">
        <v-col v-for="image in selectedImages" :key="image" class="d-flex child-flex" cols="6" sm="6" md="6" lg="4" xl="3">
          <v-card>
          <v-img
            :src="`https://api.col.spritfest.se/upload/thumb/${image}`"
            aspect-ratio="1"
            class="grey lighten-2"
            @click="selectImage(image)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <div v-if="isGod" align="center" class="user-id"><small>{{ image.split("_")[1].split("-")[0] }}</small></div>
          </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="pageCount > 1 && !fullScreenImage" class="footer">
      <v-pagination
        v-model="imagePage"
        :length="pageCount"
      />
    </div>
  </div>
</template>

<script>
import { getImageList, deleteImage } from "@/base/upload.js"
import { getUserId } from "@/base/user.js"

export default {
  name: "Home",
  props: [
    'userAndTeam'
  ],
  data: function () {
    return {
      imageNames: [],
      selectedImages: [],
      fullScreenImage: undefined,
      fullScreenImageLoading: false,
      imagePage: 1,
      getImagesInterval: undefined,
      touch: { startX: 0, currentX: 0 },
      touchTimer: undefined,
      swipeHandled: false,
    };
  },
  watch: {
    imagePage() {
      this.fullScreenImage = undefined;
      this.fullScreenImageLoading = false;
      const start = (this.imagePage-1)*this.numberOfImagesToShow;
      const end = start + this.numberOfImagesToShow;
      this.selectedImages = this.imageNames.slice(start, end);
    }
  },
  computed: {
    isGod() {
      return this.userAndTeam?.isGod;
    },
    pageCount() {
      return this.imageNames.length ? Math.ceil(this.imageNames.length/this.numberOfImagesToShow) : 1;
    },
    numberOfImagesToShow() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 6;
        case 'sm': return 6;
        case 'md': return 4;
        case 'lg': return 6;
        case 'xl': return 8;
      }
      return 3;
    },
  },
  methods: {
    getSwipeDistance() {
      const deltaX = this.touch.currentX - this.touch.startX;
      if (deltaX < 0) {
        if (this.imagePage < this.pageCount) {
          return deltaX/4 + "px";
        }
      } if (deltaX > 0) {
        if (this.imagePage > 1) {
          return deltaX/4 + "px"
        }
      }

    },
    changeImage(offset) {
      const imageIndex = this.imageNames.findIndex( i => i === this.fullScreenImage);
      this.fullScreenImage = this.imageNames[imageIndex + offset];
    },
    handleSwipe() {
      if (this.swipeHandled) {
        return;
      }
      const deltaX = this.touch.currentX - this.touch.startX;
      if (deltaX < -150) {
        if (this.fullScreenImage) {
          this.changeImage(1);
          this.stopSwipe();
        } else {
          if (this.imagePage < this.pageCount) {
            this.imagePage++;
            this.touch.currentX = this.touch.startX = 0;
          }
        }
      }
      if (deltaX > 150) {
        if (this.fullScreenImage) {
          this.changeImage(-1);
          this.stopSwipe();
        } else {
          if (this.imagePage > 1) {
            this.imagePage--;
            this.touch.currentX = this.touch.startX = 0;
          }
        }
      }
    },
    touchStart(e) {
      this.touch.currentX = this.touch.startX = e.changedTouches?.[0]?.clientX;
      this.swipeHandled = false;
    },
    touchMove(e) {
      if (e.changedTouches.length > 1) {
        this.swipeHandled = true;
        return;
      }
      this.touch.currentX = e.changedTouches?.[0]?.clientX;
      this.handleSwipe();
      clearTimeout(this.touchTimer);
      this.touchTimer = setTimeout(() => this.stopSwipe(), 2000);
    },
    touchEnd() {
      this.touch.currentX = this.touch.startX = 0;
    },
    stopSwipe() {
      this.touch.currentX = this.touch.startX = 0;
      this.swipeHandled = true;
    },
    isMyImage(image) {
      const userId = getUserId();
      return image && image.includes(userId);
    },
    getImageSrc(image) {
      return `https://api.col.spritfest.se/upload/dcim/${image}`;
    },
    getImageUrl(image) {
      return `url("${this.getImageSrc(image)}")`;
    },
    async deleteImage(image) {
      await deleteImage(image);
      this.fullScreenImage = undefined;
      this.getImages();
    },
    selectImage(image) {
      if (image) {
        this.fullScreenImageLoading = true;
        const imageUrl = this.getImageSrc(image);
        let preloaderImg = document.createElement("img");
        preloaderImg.src = imageUrl;
        preloaderImg.addEventListener('load', (event) => {
          if (this.fullScreenImageLoading) {
            preloaderImg = null;
            this.fullScreenImage = image;
            this.fullScreenImageLoading = false;
          }
        });
      } else {
        this.fullScreenImage = image;
      }
    },
    updateFirstPage() {
        if (this.imagePage === 1 && this.imageNames.length) {
          this.selectedImages = this.imageNames.slice(0, this.numberOfImagesToShow);
        }
    },
    async getImages() {
      if (this.imageNames.length) {
        const images = await getImageList(this.imageNames[0]);
        if (!images) {
          return;
        }
        if (images.isFull) {
          this.imageNames = images.files.reverse();
        } else {
          this.imageNames.unshift(...images.files.reverse());
        }
      } else {
        const images = await getImageList();
        if (!images) {
          return;
        }
        this.imageNames = images.files.reverse();
      }
      this.updateFirstPage();
    },
    keylistener(e) {
      if (e.key === "ArrowLeft") {
        this.changeImage(-1);
      } else if (e.key === "ArrowRight") {
        this.changeImage(1);
      }
    }
  },
  mounted() {
    this.getImages();
    this.getImagesInterval = setInterval(() => this.getImages(), 5000);
  },
  created() {
    window.addEventListener("keydown", this.keylistener);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keylistener);
    clearInterval(this.getImagesInterval);
  }
};
</script>
<style scoped>
.img-div-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-div {
  position: relative;
  min-height: calc(100vh - 130px);
  background-size: contain;
  background-position: center;
}
.user-id {
  position: absolute;
  background-color: #f0f0f0a7;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  bottom: 0;
  right: 0;
}
.image-root {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.close-image-button {
  position: absolute;
  top: 5px;
  left: 45%;
  padding: 5px;
  border: 1px solid #00000050;
  border-radius: 50%;
}
.delete-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #f0f0f0a7;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
</style>
