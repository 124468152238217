<template>
  <div v-if="!quizInfo" class="img-div-loader">
    <v-progress-circular
      class="img-div"
      indeterminate
      color="grey lighten-5"
    ></v-progress-circular>
  </div>
  <div v-else-if="quizInfo.HideQuiz">
    <h1>Här kanske det kommer ett quiz om jag orkar...</h1>
  </div>
  <div v-else>
    <v-card v-for="q in quizInfo.questions" :key="q.id" class="ml-2 mr-2 mt-4 mb-6" :disabled="offline">
      <v-container fluid>
        {{ q.id + ". " + q.question }}
        <hr>
        <v-radio-group :value="q.answer" @change="handleAnswer(q, $event)" column :disabled="!quizInfo.EnableQuiz">
          <v-radio
              :label="'1. ' + q.options['1']"
              color="red"
              value="1"
            ></v-radio>
            <v-radio
              :label="'X. ' + q.options['x']"
              color="red"
              value="x"
            ></v-radio>
            <v-radio
              :label="'2. ' + q.options['2']"
              color="red"
              value="2"
            ></v-radio>
        </v-radio-group>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { getQuiz, setAnswer } from "@/base/quiz.js"

export default {
  name: "Quiz",
  props: [
    'userAndTeam'
  ],
  data: function () {
    return {
      isLoading: true,
      quizInfo: undefined,
      updateInterval: undefined,
      offline: true,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    handleAnswer(q, e) {
      setAnswer(q.id, e);
      q.answer = e;
    },
    async refreshQuiz() {
      getQuiz().then( quiz => {
        if (quiz) {
          this.isLoading = false;
          this.quizInfo = quiz;
          this.offline = false;
        } else {
          this.offline = true;
        }
      });
    }
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    this.refreshQuiz();
    this.updateInterval = setInterval(() => {
      this.refreshQuiz();
    }, 5000);
  }
};
</script>
<style scoped>
.img-div-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc( 50vh - 50px);
}
.image-root {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: aquamarine;
}
</style>
